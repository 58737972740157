import { Link } from "react-router-dom";

import ImgAnimationHeader from "../../acets/img_site/header-img.svg"
import ImgAnimationCarrinho from "../../acets/img_site/carrinnho.svg"
import ImgCellContato from "../../acets/img_site/imgrelogiocontato.svg"

import IconMenuPreco from"../../acets/img_site/precos.svg"
import IconMenuHome from"../../acets/img_site/home.svg"
import IconMenuVantagens from"../../acets/img_site/servicos.svg"
import IconMenuContato from"../../acets/img_site/contato.svg"
import IconMenuSobre from"../../acets/img_site/sobre.svg"

import Imgebook01 from"../../acets/img_leadpage/ebook01.png"
//import Docebookpdf1 from "../../acets/pdfs/ebookgratuito02.pdf"

import ImgIconCheck from "../../acets/img_site/checked.svg"
import ImgIconNocheck from "../../acets/img_site/nochecked.svg"
import ImgCell from "../../acets/img_site/cell.png"
import LogoCell from "../../acets/img_icon/logocellsite.svg"
//import ImgWpp from "../../acets/img_site/wpp.jpg"
import ImgWpp from "../../acets/img_icon/wpp.png"
import ImgNewWpp from "../../acets/img_site/newwpp.svg"
import { useEffect, useState } from "react";
import axios from "axios";
import Contador from "../partils/ContatorRegressivo"



export default function PageIndex(){
   
const [terms,setTerms]=useState(true)
const [newlaster,setnewlaster]=useState(false)

const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
    nome: '',telefone:'',email:'',porte:'LeadEbook'
}
const [values, setValues]=useState(initialValues) // INICIANDO UM STATE

useEffect(() => {
    const timeoutId = setTimeout(() => {
            setnewlaster(true)
    }, 20000); // Tempo em milissegundos para o timeout
    return () => clearTimeout(timeoutId);
  }, []);


function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
    const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
    setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
}
function onSubmit(ev){
        ev.preventDefault();
        //nome: '',telefone:'',email:'',porte:'MEI'
        //const inputIsChecked = document.getElementById('vehicle1');
        //console.log(inputIsChecked)
        if(values.nome,values.telefone===""){
            alert("ATENÇÃO. Preencha os campos de nome, telefone, aceite de email e porte obrigatoriamente")
        }
        else{
            axios.post('/api/leads',values).then(()=>{
                setValues(initialValues);
                alert("Sucesso! o ebook será baixado em breve!")
                const link = document.createElement('a');
                link.href = '/pdfs/ebookgratuito02.pdf';
                link.download = '/pdfs/ebookgratuito02.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        }
}

  /*
window.Notification.requestPermission(permission=>{
    if(permission==='granted'){
        new window.Notification('Habits',{
            body:'Textoh'
        })
    }
})
*/
//---------------------------------------------------------------------------------
    return(
      <div className="w100 column center">
        <section className="w100 h80  column center pcb50">
            
            <div className="w80 wmax1200 pcb20 row around">
                    
                    <div className="w50 column cell-tamanho ">
                        <h4 className="w100 cell-text-subtitle cell-margin10 ma10b0">10 Passos para a Sua Oficina Lucrar Mais em 2025</h4>
                            <img src={Imgebook01} alt="imagem capa do ebook" className="w60 cell-tamanho"/>
                            <label className="ma10b0 cell-tamanho roboto cell-text-smile">🚀 Baixe agora o eBook GRATUITO: 10 Passos para a Sua Oficina Lucrar Mais em 2025!
Descubra estratégias práticas para aumentar seus lucros, otimizar sua gestão e atrair mais clientes. Não perca essa oportunidade! 📈🔧 👉 Baixe agora e transforme sua oficina!
                            </label>
                    </div>
                    <div className="w50 column center cell-tamanho">
                        <div className="w80 h40 cell-tamanho  cont-circ8 column center b2 sombra wmax1200">
                            <form className="w80 pcb50" onSubmit={onSubmit} autoComplete='off'>
                                <h4>Baixar Ebook Grátis</h4>
                                <label className="c5">Nome completo:</label>
                                <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                                <label className="c5">Numero de telefone com DDD:</label>
                                <input type="number" name="telefone" onChange={onChange} value={values.telefone}></input>
                                <label className="c5">Endereço de e-mail:</label>
                                <input type="email" name="email" onChange={onChange} value={values.email}></input>
                                <label className="c5">Termo:</label>
                            
                                <div className="pcb10">
                                    <input 
                                        type="checkbox" 
                                        id="vehicle1" 
                                        className="pp" 
                                        checked={values.acceptEmails} 
                                        onChange={(e) => setValues({ ...values, acceptEmails: e.target.checked })}
                                    />
                                    <label className="pp c5" htmlFor="vehicle1">Aceito receber emails</label>
                                </div>
                                {!values.acceptEmails || values.email === '' || !values.email.includes('@') || values.nome === '' || values.telefone === ''?
                                <div className="w100 b6 pcb10 column center cont-circ c2" onClick={()=>alert('OPS.... Preencha os campos nome, email e telefone e insira o seu melhor email para baixar o Ebook')}>Baixar Ebook</div>:
                                <button type="submit" value="Continuar com o cadastro" className="btn06 w100"> Baixar Ebook</button>
                                }
                            </form>
                         </div>
                    </div>
            </div>
        </section>        
      

        
       
        <footer className="w100 h5 b1 row center">
            <label className="c6 cen">MobiAPPs - 2025 Copyright - todos os direitos reservados </label>
        </footer>
      </div>
    )
}

