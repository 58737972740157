import  { useEffect,useState } from 'react'
import axios from "axios";
import IconLixeira from '../../acets/img_icon/lixo.svg'
import OpemDetaisPedido from '../../acets/img_icon/IconOpemDetailsPedido.svg'
import IcoBin from '../../acets/img_icon/bin.svg'


export default function ItensPedido(props){
    const [openModalEcluir,setOpenModalEcluir]=useState(false)
    const [idEcluir,setidExluir]=useState(false)
    const [openSucess,setOpenSucess]=useState(false)
    const [dataItens, setDataItens] = useState([])
    const [recebido,setRecebido]=useState(0)
    const headers = {
      'Content-Type': 'application/json',
    };
    useEffect(()=>{
      rec();
    },[props.id])
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
      
        //setCountSt(count);     rt
    }
    
    //console.log(props)
    const exclui = function(id){//EXCLUI
      //console.log("aqui")
        axios.delete('/api/id_pivo_orcamento_produto/' + id).then(resp=>{
          axios.get('/api/id_pivo_orcamento_produto/'+props.id).then(resp=>{
            setDataItens(resp.data)
          })
        })
    }
  
    function opemdetails(id){
      axios.get("api/pivo_orcamento_produto/"+id).then(resp=>{
        //console.log(props.id+"---------5555555555555555555555")
        setDataItens(resp.data)
        setOpenSucess(true)
        }) 
      axios.get("api/baixas_orcamento/sum/"+props.id,{headers}).then(resp=>{
        //console.log(props.id+"---------5555555555555555555555")
        setRecebido(resp.data)
      }) 
    }
    //console.log(dataItens)
    var total = dataItens.reduce(getTotal, 0);

    function getTotal(total, item) {
    var precoProduto = parseFloat(item.preco*item.quantidade);
    return total + precoProduto;
    }
   //console.log(dataItens)


const final = dataItens.map(namesString => (
  <div key={namesString.id_pivo_orcamento_produto} className='w100 row between ma2'>
      <label className='w5 c12'>{namesString.quantidade}</label>
      <label className='w60 fs09em c12'>{namesString.produto_servico.descricao}</label>
      <div className='w15 cell-tamanho-metade30'>
        <label className='cell-texto-p c12'>R$:</label>
        <label className='c12'>{namesString.preco}</label>
       </div>
  </div>
));
  //console.log(final)

  

  
  // Exibição do preço total de todos os itens
  //const precoTotalString = precoTotal.toFixed(2);
    
    // Utilize a variável precoTotalString onde você precisa exibir o preço total de todos os itens.


    //{final} <label className='w80 b27'>{Object.keys(JSON.parse(namesString.opcionais)).join(',')}</label>
    return(
      <div className='w100 row between '>
        {openModalEcluir&&
              <div className='w100 h100 fundo-opaco posifixedinicio left0 column center'>
                <h5 className='fb c3 cen ma25b0'>Deseja excluir permanentemente?</h5>
                <div className='row'>
                  <button className='ml10' onClick={()=>{exclui(idEcluir);setOpenModalEcluir(false)}}>Confirmar</button>
                  <button className='ml10 btn05' onClick={()=>setOpenModalEcluir(false)}>Cancelar</button>
                </div>
              </div>
        }
        <div className='w100 column'>
            {!openSucess&&
            <label className='w20 column center cell-tamanho-metade30 pp fs09em cell-paddingcb10px fs08em fb c5 pcb7' onClick={()=>opemdetails(props.id)}>+ DETALHES </label>
            }
        </div>
        {total?<div className='linha w100'></div>:<div></div>}
        {final}
        <div className='w100 row between'>
          {total?
          <>
          <div className='row '>
            <div className='column '>
              <label className='fs08em fw500 c12'>Recebido</label>
              <label className='c12'>R$:{recebido}</label>
            </div>
            <div className='column ml5'>
              <label className='fs08em fw500 c12'>Em aberto</label>
              <label className='c12'>R${total-recebido}</label>
            </div>
          </div>
          
          <div className='row'>
            <label className="fw500 ml5">Total </label>
            <h5 className='fw500 '> R$:{total}</h5>
          </div>
          </>:
          <label></label>}
        </div>
      </div>
    )
}