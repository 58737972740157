import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import IconDashboard from '../../acets/img_icon/menu/dashboard.svg'
import IconNovoOrcamento from '../../acets/img_icon/menu/novoorcamento.svg'
import IconOrcamento from '../../acets/img_icon/menu/orcamentos.svg'
import IconProduto from '../../acets/img_icon/menu/produtos.svg'
import IconFornecedor from '../../acets/img_icon/menu/fornecedor.svg'
import IconCliente from '../../acets/img_icon/menu/clientes.svg'
import IconCutos from '../../acets/img_icon/menu/custos.svg'
import IconEmpresa from '../../acets/img_icon/menu/empresa.svg'
import IconSair from '../../acets/img_icon/menu/sair.svg'
import IconAgenda from'../../acets/img_icon/menu/calender.svg'
import IconCaixa from'../../acets/img_icon/menu/caixa.svg'

import iconmenulateral from '../../acets/img_icon/menubarralateral/menu.svg'
import iconmenusegundario from '../../acets/img_icon/menubarralateral/menusegundario.svg'
import iconmenufechalateral from '../../acets/img_icon/menubarralateral/menufecha.svg'
import iconloglateral from '../../acets/img_icon/menubarralateral/log.svg'
import iconnotifilateral from '../../acets/img_icon/menubarralateral/notifica.svg'

export default function MenuPainelAdmin(){
  

  const tenancylocal = localStorage.getItem('ClienteTenancy')
  const usernivel = localStorage.getItem('usernivel') 
  const nomelog = JSON.parse(localStorage.getItem('ItemUsers'));

  //console.log(nomelog.email)
  axios.defaults.headers.common['id_tenancy'] = tenancylocal;
  axios.defaults.headers.common['nome'] = nomelog.email;
  const navigate = useNavigate();
  const [selected, setSelected] = useState(localStorage.getItem('selected') || '');

  const [openlog,setOpenlog] = useState(false)
  const [openmenu,setOpenMenu] = useState(false)
  const [opennotifica,setOpennotifica] = useState(false)
  
  const [datanotifica,setDatanotifica]=useState([])
  const [datalog,setDatalog]=useState([])

  const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
    titulo: '',	descricao: '',	troco: '',bairro:'0',rua:'0',numero:'0',complemento:'0',observacoes:'null',	cliente: '', nome:''
  }
  
  const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
  const handleClick = (item, path) => {
    setSelected(item);
    localStorage.setItem('selected', item); // Salva no localStorage
    navigate(path);
  };
  function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
    const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
    setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
  }
  useEffect(()=>{
    rec();
    if ((window.screen.availHeight < 1234) &&(window.screen.availWidth < 1234)){
      //console.log("tela pequena")
    }
  else{
    setOpenMenu(true)
  //console.log("tela pequena")
  }
  setSelected(localStorage.getItem('selected') || '');


    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const deslogar =()=>{
      localStorage.removeItem('keyItemNameg');
      localStorage.removeItem('keyItemName');
      localStorage.removeItem('ItemUsers')
      localStorage.removeItem('usernivel')
      window.location.href = "/";
    }

    function rec(){
      axios.get('api/log/tenancy').then(resp=>{
        const logmax = resp.data.map(loglop=>loglop.id_log);
        const maxlog = Math.max(...logmax);

        setDatalog(resp.data)
        const notificLenght = localStorage.getItem('logLenght') || 0; // Se não houver valor no localStorage, defina como 0
        if (maxlog > notificLenght) {
          alert("Um cliente abriu o link de orçamento, confira no meu de Log");
          localStorage.setItem('logLenght', maxlog); // Atualiza o maior ID no localStorage
        } else {
          localStorage.setItem('logLenght', maxlog); // Garante que o localStorage seja sempre atualizado
        }
      })
      

      axios.get('/api/notificacoes').then(resp => { 
        const notificacoes = resp.data.map(notificacao => notificacao.id_notificacao); // Extrai os IDs das notificações
        const maxId = Math.max(...notificacoes); // Encontra o maior id_notificacao
        const invalid = {ERRO: 'token invalido 3'}&&{ERRO: 'token invalido 2'};
        //console.log(resp)
        if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
          navigate('/login');
          window.location.reload(true);
        }
        else{
          setDatanotifica(resp.data);
        }
        
        const notificLenght = localStorage.getItem('notificLenght') || 0; // Se não houver valor no localStorage, defina como 0
        if (maxId > notificLenght) {
          alert("Você tem uma nova notificação no menu");
          localStorage.setItem('notificLenght', maxId); // Atualiza o maior ID no localStorage
        } else {
          localStorage.setItem('notificLenght', maxId); // Garante que o localStorage seja sempre atualizado
        }
      });
      
      
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
      ev.preventDefault();
      if(values.descricao===''||values.titulo===''){
          alert("Atencao, preencha todos os campos!")
           }else{
          axios.post('/api/notificacoes',values).then(()=>{
              setValues(initialValues);
              rec();
          })
      }
  }
  const exclui = function(id){//EXCLUI
    axios.delete('/api/notificacoes/' + id).then(()=>{
        rec();
        setValues(initialValues)
    })
  }
    const finalnotifica = datanotifica.map(namesString=>
      <div key={namesString.id_notificacao} className="column ma10b0 linha">
        <label className="fs08em c1 fb w90 ma5">{namesString.titulo}</label>
        <label className="w90 fs08em">{namesString.descricao}</label>
        <label className="fs06em w90 fim pcb10 fb">{namesString.createdAt}</label>
        {tenancylocal==='autodisel'&&<div className="w100 row end"><button className='w40 fs06em fb btn04' onClick={()=>exclui(namesString.id_notificacao)}>Excluir</button></div>}
      </div>
      
    )
    const datalogfilter = datalog.filter(datalog => datalog.nome !== null);
    const finallog = datalogfilter?.map(namesString=>
      <div key={namesString.id_notificacao} className="column ma10b0 linha">
        <label className="fb fs08em c1 w90 ma5">{namesString.nome?`${namesString.nome}`:'um cliente'}</label>
        <label className="w90 fs08em">Acessou o link do orçamento</label>
        {/*<label className="w90 fs06em pcb10">{namesString.url}</label>*/}
        <label className="w90 fs08em">{namesString.device?.slice(0,40)}</label>
        <label className="fs06em w90 fim pcb10 fb">
          {new Date(namesString.createdAt).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo', // Substitua pelo fuso horário que você deseja
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })}
        </label>
      </div> 
    )
   // console.log(datanotifica)
    return(
      <>
      <div className="posifixedinicio top0 left0 we40px pp z16 b2">          
              {
                openmenu
                ?
                <div>

                <img 
                  src={iconmenufechalateral} 
                  className="w100" 
                  alt="menu lateral" 
                  onClick={() => {
                    setOpenMenu(false);
                    setOpennotifica(false);
                    setOpenlog(false);
                  }} 
                />
                </div>
                :
                <img 
                  src={iconmenulateral} 
                  className="w100 " 
                  alt="menu lateral" 
                  onClick={() => {
                    setOpenMenu(true);
                    setOpennotifica(false);
                    setOpenlog(false);
                  }} 
                />
                }
      </div>
      <div className="w20 h90 cont-circ3 column cell-none cell-margin10 ">
        
      </div>
      
      
      <div className="w20 desktop-height-100 cont-circ3 row cell-tamanho cell-margin10 posifixedinicio z15">
        
       
       
              
        {openlog&&<>
          <div className="w100 h100 fundo-opaco  posiabstop0 rigth0 top0 z1 desktop-none" onClick={()=>{setOpenlog(false); setOpenMenu(false); setOpennotifica(false)}}>
                
          </div>
          <div className="w100 b2 column around he100 sombra-small z4">
            <div className="w90 h100 column center" style={{ overflowY: 'auto', height: '100%' }}>      
                  <div className="w100 he100 pcb50" style={{ overflowY: 'auto' }}>
                    <div className="w100 ma25b0">
                      <label>Registro de Log {datalogfilter.length}</label>
                    </div>
                {finallog}
              </div>
            </div>
          </div>
        
        </>
        }
        {opennotifica&&<>
          <div className="w100 h100 fundo-opaco  posiabstop0 rigth0 top0 z1 desktop-none" onClick={()=>{setOpenlog(false); setOpenMenu(false); setOpennotifica(false)}}>
                
          </div>
          <div className="w85 b2 column around he100 sombra-small z4">

          <div className="w90 h100 column center" style={{ overflowY: 'auto', height: '100%' }}>
            
            <div className="w100 he100 pcb50" style={{ overflowY: 'auto' }}>
              <div className="w100 ma25b0">
                <label>Registro de notificações {datanotifica.length}</label>
              </div>
              
              {tenancylocal==='autodisel'&&
              <form onSubmit={onSubmit}>
                <div className="w90">
                  <label>Titulo</label>
                   <input type="text" name="titulo" onChange={onChange} value={values.titulo} autoComplete="off"></input>
                </div>
                <div className="w90">
                  <label>Subtitulo</label>
                  <input type="text"  name="descricao"  onChange={onChange} value={values.descricao} autoComplete='off'></input>
                </div>
                <div className="w90">
                  <input type="submit"/>
                </div>
              </form>}
              {finalnotifica}
            </div>
          </div>
        </div>
        </>
        }
        
        {openmenu&&<>
          <div className="w100 h100 fundo-opaco  posiabstop0 rigth0 top0 z1 desktop-none" onClick={()=>{setOpenlog(false); setOpenMenu(false); setOpennotifica(false)}}>
              
          </div>
          <div className="w85 b2 column around h100 sombra-small z4">
            
            <label className="cell-none fs09em ">
              {tenancylocal.charAt(0).toUpperCase() + tenancylocal.slice(1)}
            </label>
            {//<label onClick={()=>setOpenMenu(false)} className="pp w90 h7 center column b27">Fechar Menu</label>
            }
            <div className="w90 column center">
            {usernivel>=20&&
              <div
                className={`w100 row center ${selected === 'dashboard' ? 'selected' : ''}`}
                onClick={() => handleClick('dashboard', '/painel')}
              >
                  {/*<img src={IconDashboard} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Dashboard</span>
              </div>
            }{/*
              <div
                className={`w100 row center ${selected === 'novo' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('novo', '/adminvendarapida')}
              >
                  {/*<img src={IconNovoOrcamento} alt="icone de painel" />}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Novo Orçamento</span>
              </div>
              */}
              <div
                className={`w100 row center ${selected === 'orcamentos' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('orcamentos', '/adminpedido')}
              >
                  {/*<img src={IconOrcamento} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Orçamentos</span>
              </div>

              <div
                className={`w100 row center ${selected === 'produtos' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('produtos', '/adminproduto')}
              >
                  {/*<img src={IconProduto} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Produtos e Serviços</span>
              </div>
              {usernivel>=20&&<>
              <div
                className={`w100 row center ${selected === 'caixa' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('caixa', '/admincaixa')}
              >
                  {/*<img src={IconCaixa} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Caixa</span>
              </div>
              <div
              className={`w100 row center ${selected === 'vendarealizada' ? 'selected' : ''} cell-paddingcb10px`}
              onClick={() => handleClick('vendarealizada', '/adminvendarealizada')}
            >
                {/*<img src={IconCutos} alt="icone de painel" />*/}
              <span className="fb w79 pc10l5 no-hover pp fs09em">Faturamento</span>
            </div>
              </>}
               
              <div
                className={`w100 row center ${selected === 'fornecedor' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('fornecedor', '/adminclientefornecedor')}
               >
                <span className="fb w79 pc10l5 no-hover pp fs09em">Fornecedor</span>
              </div>

              <div
                className={`w100 row center ${selected === 'funcionario' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('funcionario', '/adminclientefuncionario')}
               >
                <span className="fb w79 pc10l5 no-hover pp fs09em">Funcionario</span>
              </div>
              
              {usernivel>=20&&
              <div
                className={`w100 row center ${selected === 'clientes' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('clientes', '/admincliente')}
              >
                  {/*<img src={IconCliente} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Clientes</span>
              </div>
              }
              {usernivel>=20&&
              <div
                className={`w100 row center ${selected === 'custos' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('custos', '/admincustoempresa')}
              >
                  {/*<img src={IconCutos} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Custos</span>
              </div>
              }
              <div
                className={`w100 row center ${selected === 'agenda' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('agenda', '/admiagenda')}
              >
                  {/*<img src={IconAgenda} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Agenda</span>
              </div>

              {tenancylocal==='autodisel'&&
                <div
                className={`w100 row center ${selected === 'log' ? 'selected' : ''} cell-paddingcb10px`}
                onClick={() => handleClick('log', '/adminlog')}
              >
                  {/*<img src={IconCutos} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Log</span>
              </div>
              }
               {usernivel>=20&&
              <div
                className={`w100 row center ${selected === 'empresa' ? 'selected' : ''}  cell-paddingcb10px`}
                onClick={() => handleClick('empresa', '/admindadosempresa')}
              >
                {/*<img src={IconEmpresa} alt="icone de painel" />*/}
                <span className="fb w79 pc10l5 no-hover pp fs09em">Dados Empresa</span>
              </div>
              }
               {/* usernivel>=20&&
                  <div
                    className={`w100 row center ${selected === 'financeiro' ? 'selected' : ''}  cell-paddingcb10px`}
                    onClick={() => handleClick('financeiro', '/adminfinanceirotenancy')}
                  >
                    {/*<img src={IconEmpresa} alt="icone de painel" /> }
                    <span className="fb w70 pc10l5 no-hover pp fs09em">Financeiro</span>
                    <div className="w7 b27 column center cont-circ7 fs06em fb pp"> Novo</div>
                  </div>
                */}

              <div className="w90 row center cell-paddingcb10px">
                <img src={IconSair} alt="icone de painel" />
                <Link to="/" className=" w79 pc10l5 pp fs09em fb" onClick={deslogar}>
                  Sair do app
                </Link>
              </div>

             

            
            
            </div>
            <label className="fs06em">Auto metricas 	&trade;</label>
            <div className="row around w80">
            
            
            <img 
              src={iconmenusegundario} 
              className="w10 cell-tamanho10 pp" 
              alt="menu lateral" 
              onClick={() => {
                setOpenMenu(true);
                setOpennotifica(false);
                setOpenlog(false);
              }} 
            />
            
            
            <img 
              src={iconnotifilateral} 
              className="w10 cell-tamanho10 pp" 
              alt="notificações laterais" 
              onClick={() => {
                setOpennotifica(!opennotifica);
                setOpenMenu(false);
                setOpenlog(false);
              }} 
            />
            <img 
              src={iconloglateral} 
              className="w10 cell-tamanho10 pp" 
              alt="log lateral" 
              onClick={() => {
                setOpenlog(!openlog);
                setOpenMenu(false);
                setOpennotifica(false);
              }} 
            />
          </div>
          
            
          
{/*
          <Link to="/adminassistenteinteligente" className="fb w90 ma10b0 row">
            <label className="pp">Assistente Inteligente</label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Paused</div>
          </Link>
        */ }
         
          
          
          {/*
          <Link to="/adminimagen" className="fb w90 ma10b0">Icones</Link>
          <Link to="/admincategoriaproduto" className="fb w90 ma10b0">Categoria de Serviços/Peças</Link>
          <Link to="/adminentregador" className="fb w90 ma10b0 row">
            <label className="pp">Entregador - </label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
        
          <Link to="/adminfrete" className=" w90 ma10b0 row">
            <label className="fb pp">Frete - </label>
            <div className="b27 column center cont-circ7 fs06em fb p5 mac0l8 pp"> Novo</div>
          </Link>
        
          <Link to="/adminfeature" className="fb w90 ma10b0">Feature<span className="fs08em text-smile-weight"> - Atualizações</span></Link>
          */}
          {
            // <Link to="/admindadosempresa" className="fb w90 ma10b0">Etiqueta e Patrimonio</Link>
            //<Link to="/adminprodutoestoque" className="fb w90 ma10b0">Estoque de produtos</Link>
          
          
            //<Link to="/adminlead" className="fb w90 ma10b0">Leads</Link>
          }
{//<Link to="/areadocliente/orcamentoAuth0/154/138" className="fb w90 ma10b0">Area do Cliente</Link>
}
      
        </div>
        </>
        }
        
        
      </div>
      </>
    )
}