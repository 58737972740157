
import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES

//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'

import Select from 'react-select';
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'

export default function AdminClienteFornecedor(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Cliente Fornecedor',
        mainstring:'nome',
        url:'/api/clientefornecedor/',
        url2:'/api/baixas_cliente_fornecedor/',
        search:'/api/clientefornecedor/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    //id_cliente: '',	nome: '',	placa: '',	carro: '',	endereco: '',	telefone: '',	email: '',	status: '',	observacao: '',	codigo_acess: '',	tenancyId: '',
   
    //	id_baixas_celiente_fornecedor	id_clientefornecedor	valor_baixa	forma_pgto	tenancyId	createdAt	updatedAt
    
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
      id_cliente: '',	status: '',	observacao: '',	saldo:'', id_baixas_celiente_fornecedor:'',	id_clientefornecedor:'',	valor_baixa:'',	forma_pgto:'',
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    const [modalpagamentos, setModalpagamentos] = useState(false)
    const [dataBaixasCliente,setDataBaixasCliente]=useState([])
    const [dataCliente,setDataCliente]=useState([])
    //FUNÇÕES=====================================
    const tlocal = localStorage.getItem('keyItemNameg')
    const headers = {
        'Content-Type': 'application/json',
        'token':tlocal,
    };
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url,{headers}).then(resp=>{
            const invalid = {ERRO: 'token invalido 3'}&&{ERRO: 'token invalido 2'};
            //console.log(resp)
            if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
              navigate('/login');
              window.location.reload(true);
            }
            else{
              setData(resp.data);
            }
        })
        //console.log(data[0])
        axios.get('/api/cliente/',{headers}).then(resp=>
            setDataCliente(resp.data)
        )
        
    }
    //console.log(dataCliente)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    function onSubmit(ev){//CADASTRAR NO BANCO DE DADOS, obs refatorado para tirar o if e else e usar o. ternario edit e cadastrar
        ev.preventDefault();
        if(values.id_cliente===''){
            alert("Atencao, preencha todos os campos!")
        }else{
            axios[values.id_clientefornecedor?'put':'post'](config.url,values,{headers}).then(()=>{
                setOpenNew(false)
                setValues(initialValues);
                rec();
            })
        }
    }
    const optionInputBind = dataCliente.map(repsValues => ({
        value: repsValues.id_cliente,
        label: repsValues.nome, // Use a propriedade apropriada para a descrição do cliente
      }));
    const handleSelectChangeCliente = (selectedOption) => {
        setValues({ ...values, id_cliente: selectedOption.value });
      };

    const editar = function(id){//EDITA VER CARREGA OS DADOS DO ITEM A SER EDITADO
        setOpenNew(true)
        //console.log(id)
        axios.get(config.url + id).then(respp=>{
            setValues(respp.data[0])
            })
        axios.get(config.url2+id,{headers}).then(resp=>{
                setDataBaixasCliente(resp.data)
                console.log(resp.data)
            })
    }
    function excluirbaixas(id) {
       // console.log(id);
        axios.delete(config.url2 + id, { headers })
          .then(() => {
            setOpenNew(false);
      
            // Após excluir, faz um GET para a lista completa, sem o ID excluído
            return axios.get(config.url2, { headers }); // Atualiza a lista completa
          })
          .then(resp => {
            setDataBaixasCliente(resp.data); // Atualiza o estado com os dados atualizados
            console.log(resp.data);
          })
          .catch(error => {
            console.error('Erro ao excluir ou atualizar a lista:', error);
          });
      }
      
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values,{headers}).then(respp=>{
            setData(respp.data)       
        })
       
    }
    
    function onSubmitBaixaCliente(ev) {
        ev.preventDefault();
      
        if (!values.forma_pgto || !values.valor_baixa) { // Verifica se os campos estão vazios
          alert("Atenção, preencha os campos!");
        } else {
          axios.post(config.url2, values, { headers })
            .then(respp => {
              setOpenNew(false);
             // console.log(respp.data);
                
              // Faz o GET para obter os dados atualizados
              return axios.get(config.url2, { headers }); // Busca a lista completa, sem usar o ID do novo registro
            })
            .then(resp => {
              setDataBaixasCliente(resp.data); // Atualiza o estado com a nova lista de dados
              //console.log(resp.data);
              setValues(initialValues)
            })
            .catch(error => {
              console.error('Erro ao submeter os dados ou carregar a lista:', error);
            });
        }
      }
      
    //console.log(data[0].ERRO==='token invalido 3')
    //COMPONENTES DE LOOP==============================
    //console.log(data)
    const final = data?.map(
        namesString =>
            <div className='w100 h4 column center'  key={namesString.id_clientefornecedor}> 
                {//<div className='w5'>{namesString.status===1?<img src={IconChecked}/>:<img src={IconNoChecked}/>}</div>
                //<div className='w5 cell-tamanho10'>{namesString.id_cliente}</div>
                }
                <div className='w95 row between linha pcb10'>
                    <div className='w70  fw600 fs09em'>{namesString.cliente?.nome}</div>
                    <div className='w20 c4 fw500'>R$:{namesString.saldo}</div>
                    <div className="w5 h4 row center pp">
                        <b onClick={()=>editar(namesString.id_clientefornecedor)}> <img src={IconEdit}></img> </b>
                    </div>
                </div>
               
            </div>
    )
        const baixas =dataBaixasCliente.map(
            namesStringBaixas=>
            <div className='row between w90 ma5' key={namesStringBaixas.id_baixas_cliente_fornecedor}>
                <label className='w30 fs08em'>{namesStringBaixas.forma_pgto}</label>
                <label className='w20'>{namesStringBaixas.valor_baixa}</label>
                <label className='w30 fs08em'>{namesStringBaixas.createdAt.slice(0,16)}</label>
                <label className='w15 pp fs08em' onClick={()=>excluirbaixas(namesStringBaixas.id_baixas_cliente_fornecedor)}>Excluir</label>
            </div>
        )
      //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    return(
        <>
        {openNew&&           
            <div className='w100 h100 posifixedinicio column center z17' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho90'>
                    <div className='w90 h20'>
                        <div className='w100'>
                            {values.id_clientefornecedor?<h5>Editar {config.pageName}</h5>:<h5>Cadastrar novo(a) {config.pageName}</h5>  }
                        </div>
                        <form onSubmit={onSubmit} autoComplete='off'>
                            <div className='ma5'>
                                <label className='c4 fs09em'>Campos obrigatórios <span className='c9'>*</span> </label>
                            </div>
                            <div>
                                <label>Cliente o cliente <span className='c9'>*</span></label>
                                <Select options={optionInputBind} onChange={handleSelectChangeCliente} className='z1 ma5' />
                            </div>
                           
                           
                            <div className='row between w100'>
                                <div className='w30'>
                                    <label>status <span className='c9'>*</span></label>
                                    <select name="status"  onChange={onChange} value={values.status}>
                                        <option value="1">Fornecedor externo</option>
                                        <option value="2">Minha oficina</option>
                                    </select>
                                </div>
                                <div className='w30'>
                                    <label>Saldo:</label>
                                    <input type="text"  name="saldo"  onChange={onChange} value={values.saldo}>
                                    </input>
                                </div>
                                <div className='w30'>
                                    <label>observacao:</label>
                                    <input type="text"  name="observacao"  onChange={onChange} value={values.observacao}></input>
                                </div>  
                            </div>
                            
                           
                            <div className='cell-margin5'>
                                <button type="submit" className='w100'>{values.id_clientefornecedor ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </form>
                        <div className='w100 row between'>
                            <button className='w50 ma10b0 btn05' onClick={()=>setModalpagamentos(true)&setOpenNew(false)}>Pagamentos</button>
                            <button onClick={()=>(setOpenNew(false) & setValues(initialValues))} className="w40 ma10b0 btn05">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
        {modalpagamentos&&
            <div className='w100 h100 posifixedinicio column center z17' >
                <div className='w100 h100 posifixedinicio fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues)&setModalpagamentos(false))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 sombra column center z10 cell-tamanho80'>
                    <h5 className='w90'>Repasse Cliente fornecedor</h5>
                    <form onSubmit={onSubmitBaixaCliente} className='b12 column center'>
                    
                        <div className='row between w90'>
                            <div className='w30'>
                                <label>Forma:</label>
                                <select name="forma_pgto"  onChange={onChange} value={values.forma_pgto} >
                                    <option value={""}> Selecione</option>
                                    <option value={"DINHEIRO"}> Dinheiro</option>
                                    <option value={"TRANSFERENCIA"}> Transferencia</option>
                                    <option value={"CART.DEBT"}> Cartao de Debito</option>
                                    <option value={"PIX"}> Pix</option>                                   
                                </select>
                            </div>
                            <div className='w30'>
                                <label>Valor:</label>
                                <input type="number"  name="valor_baixa"  onChange={onChange} value={values.valor_baixa} >
                                </input>
                            </div>
                            <div className='w30'>
                                <label>Ação:</label>
                                <input type="submit"  value="Regist. PGTO" 
                                className='cell-texto-p'
                                ></input>
                            </div>  
                        </div>
                    </form>
                    <div className='w100 column he30'>
                        <div className='h30 w100 overflowY'>
                            <div className='column center w100'>

                            
                            <div className='row between w90 ma5'>
                                <label className='w30 fw500'>Forma</label>
                                <label className='w20 fw500'>Valor</label>
                                <label className='w30 fw500'>Data</label>
                                <label className='w15 fw500'>Ação</label>
                            </div>
                            
                            {baixas}

                            </div>
                            
                        </div>
                        
                    </div>
                    <div className='w90 row between'>
                        <label>Sem opções</label>
                    <button onClick={()=>(setOpenNew(false) & setValues(initialValues) &setModalpagamentos(false))} className="w40 ma10b0 btn05">Cancelar</button>
                    </div>
                </div>
            </div>
        }
        <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center  celular">
                    
                <div className="w100 row between cell-tamanho celular">
                    <div className="h100 w100 cont-circ3 column  cell-tamanho cell-paddingcb10">
                        <h5 className='w90'> {config.pageName}</h5>
                        {<form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='btn10 b37' onClick={()=>(setOpenNew(true))&setValues(initialValues)} >Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder=' Search'/>
                            </div>
                            <div className='w20 b37'>
                                <input type="submit" value="Pesquisar" style={{backgroundColor: TenancyColor().backgroundColor}} className='cell-text-smile08'/>
                            </div>
                        </form>}
                        <label className='w90 ma5'>Para adicionar um cliente fornecedor primeiro cadastre como cliente e após cadastre como cliente fornecedor nesta pegina.</label>
                        <div className='w90 h10 column b2 borda1'>
                            <div className='w100 b36 c3 pcb10 row center'>
                                <div className='w95 row between'>
                                    <label className='c2 fs09em w65'>Nome</label>
                                    <label className='c2 fs09em w10 cell-none'>Saldo</label>
                                    <label className='c2 fs09em w10 cell-tamanho15'>Editar</label>
                                </div>
                                   
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}





